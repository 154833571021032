import { Box } from '@chakra-ui/react'

interface RenderHTMLProps {
  children: string
}

const RenderHTML = ({ children }: RenderHTMLProps) => {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: children }}
      sx={{
        'a, a span': {
          color: '#2b6cb0 !important'
        },
        p: {
          marginTop: '0.25rem',
          lineHeight: 1.75
        },
        'ul, ol': {
          marginLeft: '1.5rem'
        },
        table: {
          width: '100%',
          borderCollapse: 'collapse',
          borderSpacing: 0
        },
        'table, th, td': {
          border: '1px solid',
          borderColor: 'gray.300'
        },
        'tbody td': {
          padding: '0.5rem'
        },
        pre: {
          backgroundColor: 'gray.100',
          padding: '0.5rem',
          border: '1px solid',
          borderColor: 'gray.300',
          borderRadius: '0.25rem'
        },
        'h1, h2, h3, h4': {
          fontWeight: 'bold'
        },
        h1: {
          fontSize: '2.5rem'
        },
        h2: {
          fontSize: '2rem'
        },
        h3: {
          fontSize: '1.5rem'
        },
        h4: {
          fontSize: '1.25rem'
        }
      }}
    />
  )
}

export default RenderHTML
