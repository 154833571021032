import Head from 'next/head'
import Script from 'next/script'

interface SEOProps {
  title: string
  description: string
}

const SEO = ({ title, description }: SEOProps) => {
  return (
    <>
      <Head>
        <title>{title} | Medwind Poznań</title>
        <meta property="og:title" content="Twoje zdrowie - nasz priorytet | Medwind" />
        <meta name="description" content={description} />
        <meta property="og:description" content={description}></meta>
        <meta property="og:url" content="https://medwind.pl/" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <meta name="google-site-verification" content="oIKHut5IsgrESUKnqlqJk4Qu2pLudoakRC4mtDtH5UU" />
      </Head>
      <Script
        id="gtag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-YQC2E1WG7Y');
            `
        }}
      />
      <Script strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-YQC2E1WG7Y" />
    </>
  )
}

export default SEO
