import {
  Icon,
  Box,
  Button,
  Center,
  Container,
  Heading,
  Link,
  SimpleGrid,
  Stack,
  Text,
  AspectRatio,
  Image
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import type { NextPage } from 'next'
import NextLink from 'next/link'
import ReactMapboxGl, { Marker } from 'react-mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { RiMapPin2Fill } from 'react-icons/ri'
import SEO from 'components/SEO'
import useStore from 'store'
import RenderHTML from 'components/RenderHTML'
import { useRouter } from 'next/router'

const Map = ReactMapboxGl({
  accessToken: 'pk.eyJ1IjoidG1hcmNpbmlha20iLCJhIjoiY2p6anVlbXBoMDdnZzNic2FscDg5b29vdSJ9.t9ipEk-64DCGaIt8ikUdYA',
  interactive: false
})

const MotionHeading = motion(Heading)
const MotionText = motion(Text)
const MotionBox = motion(Box)
const heroFadeIn = {
  initial: {
    opacity: 0,
    y: 32
  },
  animate: {
    opacity: 1,
    y: 0
  }
}

interface HomeProps {
  configData: Record<string, any>
  homeData: Record<string, any>
}

const Home: NextPage<HomeProps> = ({ configData, homeData }) => {
  const router = useRouter()
  const toggleContactModal = useStore((state) => state.toggleContactModal)
  const sections = homeData.attributes.sections
  return (
    <Box>
      <SEO
        title="Przychodnia Poznań, laryngologia, ortopedia, logopedia, chirurgia"
        description="Prywatna specjalistyczna przychodnia w Poznaniu laryngologia, ortopedia, logopedia, chirurgia, rehabilitacja. Poradnia tel. 61 830 60 30. Poradnia Poznań Górczyn, Świerczewo, Grunwald – Medwind"
      />
      <AspectRatio ratio={[1.25, 1.5, 2.25]} marginTop={[0, 0, '1rem']}>
        <Box position="relative">
          <MotionBox
            position="absolute"
            as="video"
            filter="brightness(50%)"
            autoPlay
            muted
            loop
            {...heroFadeIn}
            transition={{ duration: 0.4, delay: 0.1 }}
          >
            <source src="/loop.mp4" type="video/mp4" />
          </MotionBox>
          <Center position="absolute" color="white" padding="1rem">
            <Stack gridGap="2rem">
              <Box>
                <MotionHeading
                  fontSize={['2rem', '2rem', '2rem', '2.5rem']}
                  fontWeight={500}
                  letterSpacing={-2}
                  {...heroFadeIn}
                  transition={{ duration: 0.4, delay: 0.1 }}
                >
                  {configData.attributes.heroTextFirstLine}
                </MotionHeading>
                <MotionHeading
                  fontSize={['2rem', '2rem', '2rem', '2.5rem']}
                  fontWeight={500}
                  letterSpacing={-2}
                  {...heroFadeIn}
                  transition={{ duration: 0.4, delay: 0.2 }}
                >
                  {configData.attributes.heroTextSecondLine}
                </MotionHeading>
                <MotionText marginTop="1rem" {...heroFadeIn} transition={{ duration: 0.4, delay: 0.3 }}>
                  A. Schopenhauer
                </MotionText>
              </Box>
            </Stack>
          </Center>
        </Box>
      </AspectRatio>
      {sections?.map((section: any, i: number) => {
        const isFirst = i === 0
        const isSecondary = !(i % 2 === 0)
        const imageUrl = section?.sectionImage?.data?.attributes?.url
        const onButtonClick = () =>
          section?.buttonUrl &&
          (section?.buttonUrl === '#contactModal' ? toggleContactModal() : router.push(section?.buttonUrl))
        return (
          <Box key={section?.id} backgroundColor={isSecondary ? 'gray.200' : 'white'}>
            <Container maxWidth="container.xl" paddingY={['4rem', '4rem', '8rem']}>
              <Heading as={isFirst ? 'h1' : 'h2'} size={isFirst ? 'xl' : 'lg'} fontWeight={500} margin={0}>
                {section?.sectionTitle}
              </Heading>
              <SimpleGrid columns={[1, 1, 2]} gap="4rem" alignItems="center" marginTop="2rem">
                <Image src={imageUrl} alt={section?.sectionTitle} />
                <Stack gap="2rem" alignItems="flex-start" order={isSecondary ? -1 : 1}>
                  <Box fontSize="1.25rem" lineHeight={1.75} maxWidth="32rem" whiteSpace="pre-line">
                    <RenderHTML>{section?.sectionContent}</RenderHTML>
                  </Box>
                  {!!section?.buttonUrl && !!section?.buttonLabel && (
                    <Link
                      fontSize="1.25rem"
                      textDecoration="underline"
                      textUnderlineOffset="0.25rem"
                      onClick={onButtonClick}
                    >
                      {section?.buttonLabel}
                    </Link>
                  )}
                </Stack>
              </SimpleGrid>
            </Container>
          </Box>
        )
      })}
      <Container maxWidth="container.xl" minHeight="12rem" marginTop="2rem">
        <Center borderTop="1px black solid" paddingY="10rem">
          <Stack gap="2rem" alignItems="center">
            <Heading fontWeight={500} textAlign="center" lineHeight={1.5}>
              Zadbaj o siebie już dziś.
              <br />
              Nie zwlekaj.
            </Heading>
            <Button
              borderRadius={0}
              size="lg"
              fontSize="1rem"
              colorScheme="black"
              backgroundColor="gray.800"
              onClick={toggleContactModal}
            >
              Umów Wizytę
            </Button>
          </Stack>
        </Center>
      </Container>
      <Map
        style="mapbox://styles/mapbox/streets-v9"
        center={[16.87202406576331, 52.372354221966674]}
        zoom={[13]}
        containerStyle={{
          height: '24rem',
          width: '100%',
          zIndex: 0
        }}
      >
        <Marker coordinates={[16.87202406576331, 52.372354221966674]} anchor="bottom">
          <Icon as={RiMapPin2Fill} boxSize="3rem" />
        </Marker>
      </Map>
    </Box>
  )
}

export async function getServerSideProps() {
  const CONFIG_URL = `${process.env.NEXT_PUBLIC_API_URL}/api/config`
  const HOME_DATA_URL = `${process.env.NEXT_PUBLIC_API_URL}/api/home?populate[0]=firstSectionImage,secondSectionImage,thirdSectionImage,sections&populate[1]=sections.sectionImage`
  const configResponse = await fetch(CONFIG_URL)
  const homeResponse = await fetch(HOME_DATA_URL)
  const { data: configData } = await configResponse.json()
  const { data: homeData } = await homeResponse.json()
  return { props: { configData, homeData } }
}

export default Home
